<template>
  <div class="container" :style="height">
    <div class="title">提现</div>
    <div class="changemain" style="padding: 30px 0;">
      <div class="popupmain">
        <div class="price">
          账户余额
          <em>
            $
            <em>{{userMoney}}</em>
          </em>
        </div>
        <dl>
          <dt>提现金额</dt>
          <dd style="width:70%;float:left;">
            <input type="text" placeholder="请输入金额" @input="inputHandle" v-model="money" />
          </dd>
          <a
            @click="allMoney"
            style="color:#00D59D;line-height:38px;font-size:14px;padding-left:3%;"
          >全部提现</a>
        </dl>
        <dl>
          <dt>选择提现方式</dt>
          <!-- <el-radio v-model="radio" label="1">支付宝</el-radio> -->
          <el-radio v-model="radio" label="2">PayPal</el-radio>
          <dd class="mt10" v-if="radio==1">
            <input type="text" placeholder="支付宝账户" v-model="username" />
          </dd>
          <dd class="mt10" v-if="radio==1">
            <input type="text" placeholder="真实姓名" v-model="realname" />
          </dd>
          <dd class="mt10" v-if="radio==2">
            <input type="text" placeholder="PayPal账户" v-model="payusername" />
          </dd>
        </dl>
        <dl>
          <dt>验证码</dt>
          <dd>
            <input type="text" placeholder="请输入验证码" style="width:70%;" v-model="code" />
            <a
              @click="sendmessage"
              :class="{active:time>0}"
              v-loading.fullscreen.lock="fullscreenLoading"
            >{{sendMessageName}}</a>
          </dd>
        </dl>
        <dl>
          <span class="left withfont">
            <el-checkbox v-model="checked" @change="remember(radio)">记住Paypal信息，方便下次提现</el-checkbox>
          </span>
        </dl>
        <button @click="withdrawalClick(radio)">确定</button>
      </div>
    </div>
    <!-- 输入错误 -->
    <el-dialog :visible.sync="innerVisible">
      <div class="mb10">{{tips}}</div>
    </el-dialog>
    <!-- 验证码连续错误5次，账号已被锁定，请您联系客服~！ -->
    <el-dialog
      :visible.sync="outVisible"
      class="outVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="mb10">
        账号已被锁定，不可提现，请您联系客服~！
        <br />
        <span style="margin-top:5px;display:inline-block;">客服邮箱:123456@qq.com</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 用户开始提现/用户点击记住银行卡,支付宝信息时/验证码/获取用户信息/取用户的支付账户信息(包含是否可以提现),取消保存信息
import {
  toCash,
  cashinsert,
  getCode,
  userselectOne,
  cashselectOne,
  cashdelete
} from "@/assets/js/api";
export default {
  name: "withdrawal",
  data() {
    return {
      height: {
        height: ""
      },
      radio: "2",
      checked: false,
      innerVisible: false,
      outVisible: false,
      userMoney: "",
      money: "",
      state: "",
      username: "",
      realname: "",
      payusername: "",
      value: "",
      code: "",
      sendMessageName: "发送验证码",
      time: 0,
      // 用户信息
      userLong: "",
      mobile: "",
      email: "",
      // 提示
      tips: "",
      fullscreenLoading: false
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    // 倒计时60s
    get() {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer);
        this.sendMessageName = "重新发送";
        return;
      }
      this.sendMessageName = this.time + "s重新发送";
    },
    inputHandle() {
      this.money = this.money.match(/\d+(\.\d{0,2})?/)
        ? this.money.match(/\d+(\.\d{0,2})?/)[0]
        : "";
    },
    sendmessage() {
      if (this.time == 0) {
        this.fullscreenLoading = true;
        getCode({
          value: this.value
        }).then(result => {
          if (result.code == 0) {
            this.time = 60;
            this.timer = setInterval(this.get, 1000);
            this.$message({
              message: "验证码发送成功，请查收短信",
              type: "success"
            });
            this.fullscreenLoading = false;
          } else {
            this.$message.error(result.msg);
            this.fullscreenLoading = false;
            return;
          }
        });
      }
    },
    // 销毁之前调用
    beforeDestroy() {
      clearInterval(this.timer);
    },
    // 全部提现
    allMoney() {
      this.money = this.userMoney;
    },
    // 确认提现
    withdrawalClick(type) {
      this.$message.closeAll();
      if ((type == 1) & (this.username == "")) {
        this.$message.error("支付宝账号不能为空");
        return;
      }
      if (type == 2 && this.payusername == "") {
        this.$message.error("paypal账号不能为空");
        return;
      }
      if (type == 1) {
        this.username = this.username;
      } else if (type == 2) {
        this.username = this.payusername;
      }
      if (this.money == "" || this.money == 0) {
        this.$message.error("请输入正确的提现金额");
        return;
      } else if (Number(this.money) > Number(this.userMoney)) {
        this.$message.error("提现金额不足");
        return;
      }
      if (this.checked == true) {
        this.remember(type);
      }
      toCash({
        money: this.money,
        state: type,
        username: this.username,
        realname: this.realname,
        value: this.mobile || this.email,
        code: this.code
      }).then(result => {
        if (result.code == 0) {
          this.$message({
            message: "提现成功",
            type: "success"
          });
          this.$router.push("/usermain");
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 记住银行卡信息
    remember(type) {
      // console.log("========", this.checked);
      this.IsChecked(type);
      // if (type == 1) {
      //   if (this.username == "" || this.realname == "") {
      //     this.$message.error("支付宝账号姓名不能为空");
      //     this.checked = false;
      //     return;
      //   } else {
      //     this.username = this.username;
      //     this.IsChecked(type);
      //   }
      // } else if (type == 2) {
      //   if (this.payusername == "") {
      //     this.$message.error("paypal账号不能为空");
      //     this.checked = false;
      //     return;
      //   } else {
      //     this.username = this.payusername;
      //     this.IsChecked(type);
      //   }
      // }
    },
    IsChecked(type) {
      if (this.checked == true) {
        if (type == 1) {
          this.username = this.username;
        } else if (type == 2) {
          this.username = this.payusername;
        }
        cashinsert({
          username: this.username,
          realname: this.realname,
          state: type,
          userLong: this.userLong
        }).then();
      } else {
        cashdelete({}).then();
      }
    },
    // 获取用户信息
    userlist: function() {
      userselectOne({}).then(result => {
        if (result.code == 0) {
          this.userLong = result.user.userLong;
          this.mobile = result.user.mobile || "";
          this.email = result.user.email || "";
          if (this.mobile != "" && this.email != "") {
            this.value = this.mobile;
          } else if (this.mobile == "") {
            this.value = this.email;
          } else if (this.email == "") {
            this.value = this.mobile;
          }
        }
      });
    },
    // 取用户的支付账户信息(包含是否可以提现)
    IscashselectOne() {
      cashselectOne({}).then(result => {
        if (result.code == 0) {
          if (result.list.length == 0) {
            this.checked = false;
          } else {
            this.checked = true;
          }
          for (let i = 0; i < result.list.length; i++) {
            this.username = result.list[i].zfbUsername || "";
            this.realname = result.list[i].zfbRealname || "";
            this.payusername = result.list[i].payUsername || "";
            if (result.list[i].state == 0) {
              this.outVisible = true;
            } else {
              this.outVisible = false;
            }
          }
        }
      });
    },
    handleClose() {
      history.back();
    }
  },
  mounted() {},
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
    this.userMoney = localStorage.getItem("userMoney");
    this.userlist(); //获取用户信息
    this.IscashselectOne();
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  }
};
</script>
